import clsx from 'clsx';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

interface Props {
    size?: number;
    className?: string;
}

export default function Spinner({ size, className }: Props) {
    return (
        <AiOutlineLoading3Quarters
            data-testid="spinner"
            className={clsx(
                'animate-spin text-black dark:text-white',
                className,
            )}
            size={size}
        />
    );
}
