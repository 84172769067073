'use client';
import clsx from 'clsx';
import { forwardRef, useState } from 'react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    label: string;
    containerClassName?: string;
    error?: boolean;
}

function PasswordInput(
    { label, containerClassName, error, ...props }: Props,
    ref: React.Ref<HTMLInputElement>,
) {
    const [shown, setShown] = useState(false);

    return (
        <label
            className={clsx('form-control h-[5rem] w-full', containerClassName)}
        >
            <div className="label">
                <span className="text-sm leading-5">{label}</span>
            </div>

            <div
                className={clsx(
                    'input input-bordered flex w-full items-center gap-2 border-black dark:bg-white',
                    error && '!border-red-500',
                )}
                data-testid="password-input"
            >
                <input
                    {...props}
                    ref={ref}
                    type={shown ? 'text' : 'password'}
                    placeholder={props.placeholder || label}
                    className={clsx(
                        props.className,
                        'w-full dark:!bg-white dark:!text-gray-900',
                    )}
                />

                <button onClick={() => setShown((s) => !s)} type="button">
                    {shown ? (
                        <MdVisibility
                            data-testid="hide"
                            className="dark:text-gray-900"
                        />
                    ) : (
                        <MdVisibilityOff
                            data-testid="show"
                            className="dark:text-gray-900"
                        />
                    )}
                </button>
            </div>
        </label>
    );
}

export default forwardRef(PasswordInput);
