import clsx from 'clsx';
import { forwardRef } from 'react';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    label: string;
    containerClassName?: string;
}

function Checkbox(
    { label, containerClassName, ...props }: Props,
    ref: React.Ref<HTMLInputElement>,
) {
    return (
        <div className={clsx('form-control', containerClassName)}>
            <label className="label flex cursor-pointer justify-start gap-x-2">
                <input
                    {...props}
                    ref={ref}
                    type="checkbox"
                    defaultChecked
                    className={clsx(
                        'checkbox-warning checkbox size-4 rounded-sm [--chkfg:white]',
                        props.className,
                    )}
                />
                <span className="label-text">{label}</span>
            </label>
        </div>
    );
}

export default forwardRef(Checkbox);
