'use client';
import { useTranslation } from '@/app/i18n/client';
import Checkbox from '@/components/checkbox';
import ErrorText from '@/components/error-text';
import PasswordInput from '@/components/password-input';
import Spinner from '@/components/spinner';
import TextInput from '@/components/text-input';
import useProtectPage from '@/hooks/use-protect-page/use-protect-page';
import { useStore } from '@/hooks/use-store';
import { getClient } from '@/lib/api';
import { passwordField } from '@/lib/zod';
import { AUTH_COOKIE, USER_EMAIL_COOKIE } from '@/middleware';
import { useProjectStore } from '@/stores/project-store';
import { useUserStore } from '@/stores/user-store';
import { Components } from '@/types/openapi';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { AxiosError } from 'openapi-client-axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import Cookies from 'universal-cookie';
import { z } from 'zod';

const schema = z.object({
    email: z.string().email('invalid_email'),
    password: passwordField(),
    remember: z.boolean(),
});

type Schema = z.infer<typeof schema>;

interface Props {
    params: { lng: string };
}

export default function LoginPage({ params: { lng } }: Props) {
    const [serverError, setServerError] = useState('');

    useProtectPage({ authenticated: false, sendTo: '/home' });

    const { t } = useTranslation(lng);

    const router = useRouter();

    const user = useStore(useUserStore, (s) => s);

    const queryClient = useQueryClient();

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<Schema>({ resolver: zodResolver(schema) });

    useEffect(() => {
        const cookies = new Cookies();
        cookies.remove(AUTH_COOKIE, { path: '/' });
        cookies.remove(AUTH_COOKIE, { path: `/${lng}` });
        cookies.remove(USER_EMAIL_COOKIE, { path: '/' });
        cookies.remove(USER_EMAIL_COOKIE, { path: `/${lng}` });
    }, [lng]);

    async function onSubmit(data: Schema) {
        setServerError('');
        const client = await getClient();

        try {
            const { data: resp } = await client.Authentication_Login_POST(
                { keepLogged: data.remember },
                data,
            );

            if (!resp.success || !resp.data?.email || !resp.data?.name) {
                const error = getResponseErrorCode(resp);

                setServerError(t(error));
                return;
            }

            await setLoginData(resp.data.email, resp.data.name, resp.data.role);
        } catch (e) {
            const error =
                e as AxiosError<Components.Schemas.LoginResultViewModel>;

            if (error.response?.data.error?.login === 'already_authenticated') {
                await client.Authentication_Logout_POST();

                await onSubmit(data);
                return;
            }

            const errorCode = getResponseErrorCode(error.response?.data);

            setServerError(t(errorCode));
        }
    }

    async function setLoginData(
        email: string,
        name: string,
        role?: string | null,
    ) {
        useProjectStore.getState().set({
            currentProject: '',
            currentProjectId: '',
        });

        try {
            const client = await getClient();

            const data = await client.Project_GetAll_GET();

            if (data.data?.length) {
                useProjectStore.getState().set({
                    currentProject: data.data[0].name!,
                    currentProjectId: data.data[0].id,
                });
            }
        } catch {}

        queryClient.removeQueries();
        user?.set({ email, name, role: role ?? '' });
        const cookies = new Cookies();
        cookies.set(AUTH_COOKIE, 'true', { path: '/' });
        cookies.set(USER_EMAIL_COOKIE, email, { path: '/' });
        router.push(`/${lng}/home`);
    }

    function getResponseErrorCode(
        response?: Components.Schemas.LoginResultViewModel,
    ) {
        return (
            (response?.error?.login as 'wrong_password_or_email') ??
            (response?.error?.subscription as
                | 'subscription_expired'
                | 'subscription_cancelled') ??
            'generic_error'
        );
    }

    return (
        <div className="flex h-max min-h-full w-full flex-1 items-center justify-center">
            <div className="card w-[90%] min-w-[300px] max-w-[400px] rounded-ee-xl rounded-es-[2rem] rounded-se-[2rem] rounded-ss-xl bg-base-100 shadow-xl">
                <form
                    className="card-body px-16"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <h1 className="text-xl font-bold">
                        {t('access_your_account')}
                    </h1>

                    <TextInput
                        {...register('email')}
                        label={t('email') + ':'}
                        placeholder={t('type_your_email')}
                        autoComplete="email"
                        error={!!errors.email}
                    />

                    <ErrorText>
                        {errors.email ? t(errors.email?.message as any) : null}
                    </ErrorText>

                    <PasswordInput
                        {...register('password')}
                        label={t('password') + ':'}
                        placeholder={t('type_your_password')}
                        type="password"
                        autoComplete="current-password"
                        error={!!errors.password}
                    />

                    <ErrorText>
                        {errors.password
                            ? t(errors.password?.message as any)
                            : null}
                    </ErrorText>

                    <Checkbox
                        {...register('remember')}
                        label={t('keep_connected')}
                        defaultChecked
                    />

                    <ErrorText>
                        {serverError ? t(serverError as any) : null}
                    </ErrorText>

                    <button
                        className="btn btn-primary text-lg font-bold text-black"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? <Spinner /> : t('access')}
                    </button>

                    <p className="mt-2 flex flex-wrap justify-center gap-x-1 text-sm">
                        <Trans
                            i18nKey="not_a_customer_question_get_an_account"
                            t={t}
                            components={[
                                <a
                                    href="https://www.lp.midastrackr.com/"
                                    className="link-blue link font-semibold no-underline"
                                    key={0}
                                />,
                            ]}
                        />
                    </p>

                    <Link
                        href={`/${lng}/recover-password`}
                        className="link-blue link mt-2 flex justify-center text-sm no-underline"
                    >
                        {t('recover_password')}
                    </Link>
                </form>
            </div>
        </div>
    );
}
