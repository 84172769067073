import clsx from 'clsx';
import { forwardRef } from 'react';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    horizontal?: boolean;
    error?: boolean;
}

function TextInput(
    { label, error, horizontal, ...props }: Props,
    ref: React.Ref<HTMLInputElement>,
) {
    return (
        <label
            className={clsx(
                'form-control h-[5rem] w-full max-w-sm',
                horizontal &&
                    'max-w-96 flex-row items-center justify-center gap-x-2',
            )}
        >
            {label ? (
                <div className="label w-64">
                    <span className="text-sm leading-5">{label}</span>
                </div>
            ) : null}

            <input
                {...props}
                ref={ref}
                type={props.type || 'text'}
                placeholder={props.placeholder || label}
                className={clsx(
                    props.className,
                    'input input-bordered w-full max-w-xs border-black dark:bg-white dark:text-gray-900',
                    error && '!border-red-500',
                )}
            />
        </label>
    );
}

export default forwardRef(TextInput);
