import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface Project {
    currentProject: string;
    currentProjectId: string;
    set: (
        partial:
            | Project
            | Partial<Project>
            | ((state: Project) => Project | Partial<Project>),
        replace?: boolean | undefined,
    ) => void;
}

export const useProjectStore = create<Project>()(
    persist(
        (set) => ({
            currentProject: '',
            currentProjectId: '',
            set,
        }),
        { name: 'midas_project' },
    ),
);
