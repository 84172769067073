import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface User {
    email: string;
    name: string;
    role: string;
    set: (
        partial: User | Partial<User> | ((state: User) => User | Partial<User>),
        replace?: boolean | undefined,
    ) => void;
}

export const useUserStore = create<User>()(
    persist(
        (set) => ({
            name: '',
            email: '',
            role: '',
            set,
        }),
        { name: 'midas_user' },
    ),
);
